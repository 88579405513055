import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { AuthenticatedNavigation } from '../../../components/layout/AuthenticatedNavigation/AuthenticatedNavigation';
import { Footer } from '../../../components/layout/Footer/Footer';
import './CreatorDashboard.css';
import { GuideManager } from '../../../components/guides/GuideManager';
import { useGuideState } from '../../../hooks/useGuideState';
import { GuideList } from './GuideList';
import { updateGuideForCreator } from '../../../api/creators/guide_operations';
import { GuideChanges, GuideDetails } from '../../../types/guide';

const createEmptyGuide = (): GuideDetails => {
  const newGuideId = `new-${Date.now()}-${Math.random().toString(36).slice(2, 7)}`;
  return {
    overview: {
      id: newGuideId,
      title: '',
      description: '',
      headerImage: null,
      audio: null,
      video: null,
      stops: [],
      url: '',
    },
    stop_details: [],
  };
};

const DashboardStats: React.FC = () => (
  <div className='creator-dashboard__stats'>
    <div className='creator-dashboard__stat-card'>
      <h3 className='creator-dashboard__stat-title'>Total Tours</h3>
      <p className='creator-dashboard__stat-value'>(coming soon)</p>
    </div>
    <div className='creator-dashboard__stat-card'>
      <h3 className='creator-dashboard__stat-title'>Total Revenue</h3>
      <p className='creator-dashboard__stat-value'>(coming soon)</p>
    </div>
  </div>
);

type View = 'dashboard' | 'guide-edit';

export const CreatorDashboard: React.FC = () => {
  const { state, actions } = useGuideState();
  const [view, setView] = useState<View>('dashboard');
  const [selectedGuideId, setSelectedGuideId] = useState<string>('');
  const [isNewGuide, setIsNewGuide] = useState(false);

  const handleGuideSelect = async (childId: string) => {
    setSelectedGuideId(childId);
    setIsNewGuide(false);
    setView('guide-edit');
  };

  const handleCreateNew = () => {
    console.log('Create new guide');
    setIsNewGuide(true);
    setSelectedGuideId(createEmptyGuide().overview.id);
    setView('guide-edit');
  };

  const handleSave = async (changes: GuideChanges) => {
    try {
      await updateGuideForCreator({ changes });
      await actions.fetchGuides();
      setView('dashboard');
      setIsNewGuide(false);
    } catch (error) {
      console.error('Failed to save guide:', error);
      // You might want to show an error message to the user here
    }
  };

  const renderContent = () => {
    if (view === 'dashboard') {
      return (
        <>
          <DashboardStats />
          <div className='creator-dashboard__header'>
            <h1 className='creator-dashboard__title'>Your Tours</h1>
            <button className='creator-dashboard__create-button' onClick={handleCreateNew}>
              <Plus size={20} />
              Create New Tour (coming soon)
            </button>
          </div>

          <GuideList
            guides={state.guides}
            onGuideSelect={handleGuideSelect}
            isLoading={state.loading}
            error={state.error}
          />
        </>
      );
    }

    if (view === 'guide-edit' && selectedGuideId) {
      return (
        <GuideManager
          guideId={selectedGuideId}
          isNewGuide={isNewGuide}
          initialGuide={isNewGuide ? createEmptyGuide() : undefined}
          onSave={handleSave}
          onNavigateBack={() => {
            setView('dashboard');
            setIsNewGuide(false);
          }}
        />
      );
    }

    return null;
  };

  return (
    <div className='creator-dashboard'>
      <AuthenticatedNavigation />
      <main className='creator-dashboard__main'>
        <div className='creator-dashboard__content'>{renderContent()}</div>
      </main>
      <Footer />
    </div>
  );
};

export default CreatorDashboard;
