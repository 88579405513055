import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import { Map, MapPin } from 'lucide-react';
import L from 'leaflet';
import './LocationEditor.css';

// Custom icon definition
const customIcon = new L.Icon({
  iconUrl:
    'data:image/svg+xml;base64,' +
    btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#026440" width="32" height="32">
      <path d="M12 0c-4.4 0-8 3.6-8 8c0 5.4 7 13.4 7.3 13.7c0.4 0.4 1 0.4 1.4 0c0.3-0.3 7.3-8.3 7.3-13.7c0-4.4-3.6-8-8-8zm0 12c-2.2 0-4-1.8-4-4s1.8-4 4-4s4 1.8 4 4s-1.8 4-4 4z"/>
    </svg>
  `),
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

interface LocationEditorProps {
  id: string;
  latitude?: number;
  longitude?: number;
  isEditing?: boolean;
  onChange?: (lat: number, lng: number) => void;
  className?: string;
}

// Add a new component to handle map center updates
const MapUpdater = ({ center }: { center: [number, number] }) => {
  const map = useMap();

  useEffect(() => {
    map.setView(center, map.getZoom());
  }, [map, center]);

  return null;
};

const MapMarker = ({
  position,
  onChange,
}: {
  position: [number, number];
  onChange: (lat: number, lng: number) => void;
}) => {
  const map = useMapEvents({
    click(e) {
      onChange(e.latlng.lat, e.latlng.lng);
      map.setView([e.latlng.lat, e.latlng.lng]);
    },
  });

  return <Marker position={position} icon={customIcon} />;
};

export const LocationEditor: React.FC<LocationEditorProps> = ({
  id,
  latitude,
  longitude,
  isEditing = false,
  onChange,
  className = '',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempLocation, setTempLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [initialLocation, setInitialLocation] = useState<{ lat: number; lng: number } | null>(null);

  const hasLocation = typeof latitude === 'number' && typeof longitude === 'number';
  const position: [number, number] = tempLocation
    ? [tempLocation.lat, tempLocation.lng]
    : hasLocation
      ? [latitude, longitude]
      : [37.7749, -122.4194];

  // When modal opens, store the initial location
  useEffect(() => {
    if (isModalOpen) {
      setInitialLocation(hasLocation ? { lat: latitude, lng: longitude } : null);
      setTempLocation(hasLocation ? { lat: latitude, lng: longitude } : null);
    }
  }, [isModalOpen, hasLocation, latitude, longitude]);

  const handleLocationChange = (lat: number, lng: number) => {
    setTempLocation({ lat, lng });
  };

  const handleCancel = () => {
    // Revert to initial location
    setTempLocation(initialLocation);
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    // Commit changes to parent state
    if (tempLocation && onChange) {
      onChange(tempLocation.lat, tempLocation.lng);
    }
    setIsModalOpen(false);
  };

  // View mode - just show map
  if (!isEditing) {
    return hasLocation ? (
      <div className={`location-editor ${className}`}>
        <div className='location-editor__map-container'>
          <MapContainer center={position} zoom={15} className='location-editor__map' scrollWheelZoom={false}>
            <MapUpdater center={position} />
            <TileLayer
              url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            />
            <Marker position={position} icon={customIcon}>
              <Popup>
                <div className='location-editor__popup'>
                  <p>Latitude: {latitude}</p>
                  <p>Longitude: {longitude}</p>
                </div>
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    ) : null;
  }

  return (
    <div className={`location-editor ${className}`}>
      {/* Location display and edit button */}
      <div className='location-editor__display'>
        <div className='location-editor__info'>
          <MapPin size={20} />
          {hasLocation ? (
            <div className='location-editor__coordinates'>
              {latitude.toFixed(6)}, {longitude.toFixed(6)}
            </div>
          ) : (
            <div className='location-editor__empty'>No location set</div>
          )}
        </div>

        <button onClick={() => setIsModalOpen(true)} className='location-editor__map-button'>
          <Map size={16} />
          {hasLocation ? 'Update Location' : 'Set Location'}
        </button>
      </div>

      {/* Preview map in edit mode */}
      {hasLocation && (
        <div className='location-editor__preview-map-container'>
          <MapContainer center={position} zoom={15} className='location-editor__preview-map' scrollWheelZoom={false}>
            <MapUpdater center={position} />
            <TileLayer
              url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            />
            <Marker position={position} icon={customIcon}>
              <Popup>
                <div className='location-editor__popup'>
                  <p>Latitude: {latitude}</p>
                  <p>Longitude: {longitude}</p>
                </div>
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      )}

      {/* Location picker modal */}
      {isModalOpen && (
        <div className='location-editor-modal'>
          <div className='location-editor-modal__content'>
            <div className='location-editor-modal__header'>
              <h3 className='location-editor-modal__title'>Select Location</h3>
              <button onClick={handleCancel} className='location-editor-modal__close' aria-label='Close modal'>
                ×
              </button>
            </div>

            <div className='location-editor-modal__body'>
              <div className='location-editor-modal__map-container'>
                <MapContainer center={position} zoom={15} className='location-editor-modal__map'>
                  <MapUpdater center={position} />
                  <TileLayer
                    url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  />
                  <MapMarker position={position} onChange={handleLocationChange} />
                </MapContainer>
              </div>

              <div className='location-editor-modal__help'>Click on the map to update the location</div>
            </div>

            <div className='location-editor-modal__footer'>
              <button onClick={handleCancel} className='location-editor-modal__cancel'>
                Cancel
              </button>
              <button onClick={handleConfirm} className='location-editor-modal__confirm'>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
