import React from 'react';
import { Type, Image, Music, Video, Heading } from 'lucide-react';
import './SectionCreationControls.css';

interface SectionCreationControlsProps {
  onAddSection: (type: 'title' | 'paragraph' | 'image' | 'audio' | 'video') => void;
}

export const SectionCreationControls: React.FC<SectionCreationControlsProps> = ({ onAddSection }) => {
  const controls = [
    { type: 'title' as const, icon: Heading, label: 'Add Heading' },
    { type: 'paragraph' as const, icon: Type, label: 'Add Paragraph' },
    { type: 'image' as const, icon: Image, label: 'Add Image' },
    { type: 'audio' as const, icon: Music, label: 'Add Audio' },
    { type: 'video' as const, icon: Video, label: 'Add Video' },
  ];

  return (
    <div className='section-creation'>
      <div className='section-creation__controls'>
        {controls.map(({ type, icon: Icon, label }) => (
          <button key={type} onClick={() => onAddSection(type)} className='section-creation__button' title={label}>
            <Icon size={20} />
            <span className='section-creation__label'>{label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};
