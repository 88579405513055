import { LocalGuideTilesList } from '../../domain/guides/PublicGuides';
import { asyncPatchRequest, asyncPostRequest } from '../../../api/requests';
import { GuideChanges, GuideOverview, StopDetails, StopSection } from '../../types/guide';

// Define API response types
interface GuideDetailsAPIResponse {
  isOk: boolean;
  overview: GuideOverview;
  stop_details: StopDetails[];
  error?: string;
}

interface APIResponse<T> {
  isOk: boolean;
  statusCode?: number;
  error?: string;
  data?: T;
}

const inferSectionType = (section: StopSection): StopSection['type'] => {
  // First check if there's already a valid type set
  if (section.type && ['title', 'paragraph', 'image', 'audio', 'video'].includes(section.type)) {
    return section.type;
  }

  // Handle potentially empty/null fields
  if (typeof section.title === 'string' && section.title.trim() !== '') return 'title';
  if (typeof section.paragraph === 'string' && section.paragraph.trim() !== '') return 'paragraph';

  // For media types, check for required properties to ensure valid resources
  if (section.captionedImage?.imageUrl) return 'image';
  if (section.audio?.audioFileUrl) return 'audio';
  if (section.video?.videoFileUrl) return 'video';

  console.warn('Could not infer section type:', section);
  return undefined;
};

export const guideDetailsForCreator = async ({
  overview_id,
}: {
  overview_id: string;
}): Promise<GuideDetailsAPIResponse> => {
  const response = (await asyncPostRequest(
    '/api/creators/guide_details',
    { overview_id },
    true
  )) as GuideDetailsAPIResponse;

  if (response.isOk) {
    // Transform the response to ensure all sections have types and maintain the GuideDetails structure
    const transformedGuide: GuideDetailsAPIResponse = {
      ...response,
      overview: response.overview,
      stop_details: response.stop_details.map((detail: StopDetails) => ({
        ...detail,
        sections: detail.sections.map((section: StopSection) => ({
          ...section,
          type: section.type || inferSectionType(section),
        })),
      })),
    };
    return transformedGuide;
  }

  return response;
};

export const updateGuideForCreator = async ({ changes }: { changes: GuideChanges }): Promise<APIResponse<void>> => {
  return await asyncPatchRequest('/api/guide_editing/update', { changes }, true);
};

export const guideListForCreator = async (): Promise<APIResponse<LocalGuideTilesList>> => {
  return await asyncPostRequest('/api/creators/local_guides', {}, true);
};
