import React from 'react';
import { Upload, Loader, Music } from 'lucide-react';
import './AudioPlayer.css';
import { useMediaUpload } from '../../../../hooks/useMediaUpload';
import { AudioResource } from '../../../../types/media';
import '../SharedStyles.css';
import { EditableText } from '../../../guides/editor/shared/EditableText';

interface AudioPlayerProps {
  id: string;
  title?: string;
  audioUrl?: string;
  className?: string;
  isEditing?: boolean;
  onChange?: (media: AudioResource | null) => void;
  required?: boolean;
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({
  id,
  title,
  audioUrl,
  className = '',
  isEditing = false,
  onChange,
  required = false,
}) => {
  const {
    isUploading,
    isDragging,
    error,
    fileInputRef,
    setIsDragging,
    openFileSelector,
    handleDrop,
    handleChange,
    acceptedFormats,
  } = useMediaUpload<'audio'>({
    type: 'audio',
    onUpload: onChange!,
  });

  const handleTitleChange = (newTitle: string) => {
    if (onChange && audioUrl) {
      onChange({
        audioFileUrl: audioUrl,
        title: newTitle,
      });
    }
  };

  if (!audioUrl && isEditing) {
    return (
      <div className={`audio-player ${className}`} id={`audio-player-${id}`}>
        <input
          ref={fileInputRef}
          type='file'
          accept={acceptedFormats}
          onChange={handleChange}
          className='media-dropzone__input-hidden'
          id={`audio-input-${id}`}
          aria-label={`Upload audio ${title || ''}`}
        />

        <div
          className={`media-dropzone ${isDragging ? 'media-dropzone--active' : ''}`}
          onDragOver={e => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop}
          onClick={openFileSelector}
          role='button'
          tabIndex={0}
          aria-labelledby={`audio-player-title-${id}`}
        >
          {isUploading ? (
            <div className='media-dropzone__uploading'>
              <Loader className='media-dropzone__spinner' />
              <span>Uploading...</span>
            </div>
          ) : (
            <>
              <div className='media-dropzone__icon'>
                <Music size={48} />
              </div>
              <div className='media-dropzone__text'>
                <span>Drag and drop or click to select an audio file</span>
              </div>
            </>
          )}
        </div>
        {error && <div className='media-error'>{error}</div>}
      </div>
    );
  }

  return (
    <div className={`audio-player ${className}`} id={`audio-player-${id}`}>
      <div className='audio-player__content'>
        {isEditing ? (
          <div className='audio-player__title-container'>
            <EditableText value={title || ''} onChange={handleTitleChange} placeholder='Add audio title (optional)' />
          </div>
        ) : (
          title && <h4 className='audio-player__title'>{title}</h4>
        )}

        <audio controls src={audioUrl} className='audio-player__controls' id={`audio-controls-${id}`} />

        {isEditing && (
          <>
            <input
              ref={fileInputRef}
              type='file'
              accept={acceptedFormats}
              onChange={handleChange}
              className='media-dropzone__input-hidden'
              aria-label='Replace audio'
            />
            <button
              onClick={openFileSelector}
              className='media-replace-button'
              disabled={isUploading}
              aria-label='Replace audio'
            >
              {isUploading ? (
                <Loader className='media-dropzone__spinner' />
              ) : (
                <>
                  <Upload size={16} />
                  <span>Replace audio</span>
                </>
              )}
            </button>
          </>
        )}
      </div>
      {error && <div className='media-error'>{error}</div>}
    </div>
  );
};
