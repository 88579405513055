import React from 'react';
import { Upload, Loader, Image } from 'lucide-react';
import { useMediaUpload } from '../../../../hooks/useMediaUpload';
import './ImageViewer.css';
import { CaptionedImage } from '../../../../types/media';
import '../SharedStyles.css';
import { EditableText } from '../../../guides/editor/shared/EditableText';

interface ImageViewerProps {
  id: string;
  imageUrl?: string;
  caption?: string;
  className?: string;
  isEditing?: boolean;
  onChange?: (media: CaptionedImage | null) => void;
}

export const ImageViewer: React.FC<ImageViewerProps> = ({
  id,
  imageUrl,
  caption,
  className = '',
  isEditing = false,
  onChange,
}) => {
  const {
    isUploading,
    isDragging,
    error,
    fileInputRef,
    setIsDragging,
    openFileSelector,
    handleDrop,
    handleChange,
    acceptedFormats,
  } = useMediaUpload<'image'>({
    type: 'image',
    onUpload: onChange!,
  });

  const handleCaptionChange = (newCaption: string) => {
    if (onChange && imageUrl) {
      onChange({
        imageUrl,
        caption: newCaption,
      });
    }
  };

  // Upload view when no image is present
  if (!imageUrl && isEditing) {
    return (
      <div className={`image-viewer ${className}`} id={`image-viewer-${id}`}>
        <input
          ref={fileInputRef}
          type='file'
          accept={acceptedFormats}
          onChange={handleChange}
          className='media-dropzone__input-hidden'
          id={`image-input-${id}`}
          aria-label={`Upload image ${caption || ''}`}
        />
        <div
          className={`media-dropzone ${isDragging ? 'media-dropzone--active' : ''}`}
          onDragOver={e => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop}
          onClick={openFileSelector}
          role='button'
          tabIndex={0}
          aria-labelledby={`image-viewer-title-${id}`}
        >
          {isUploading ? (
            <div className='media-dropzone__uploading'>
              <Loader className='media-dropzone__spinner' />
              <span>Uploading...</span>
            </div>
          ) : (
            <>
              <div className='media-dropzone__icon'>
                <Image size={48} />
              </div>
              <div className='media-dropzone__text'>
                <span>Drag and drop or click to select an image</span>
              </div>
            </>
          )}
        </div>
        {error && <div className='media-error'>{error}</div>}
      </div>
    );
  }

  // View mode with existing image
  return (
    <div className={`image-viewer ${className}`}>
      <div className='image-viewer__content'>
        <img src={imageUrl} alt={caption || ''} className='image-viewer__image' />

        {isEditing ? (
          <EditableText
            value={caption || ''}
            onChange={handleCaptionChange}
            placeholder='Add image caption (optional)'
          />
        ) : (
          caption && <p className='image-viewer__caption'>{caption}</p>
        )}

        {isEditing && (
          <>
            <input
              ref={fileInputRef}
              type='file'
              accept={acceptedFormats}
              onChange={handleChange}
              className='media-dropzone__input-hidden'
              aria-label='Replace image'
            />
            <button
              onClick={openFileSelector}
              className='image-viewer__replace'
              disabled={isUploading}
              aria-label='Replace image'
            >
              {isUploading ? (
                <Loader className='media-dropzone__spinner' />
              ) : (
                <>
                  <Upload size={16} />
                  <span>Replace image</span>
                </>
              )}
            </button>
          </>
        )}
      </div>
      {error && <div className='media-error'>{error}</div>}
    </div>
  );
};
