import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeft, Plus } from 'lucide-react';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { GuideOverviewEdit } from './editor/overview/GuideOverviewEdit';
import { GuideStopEdit } from './editor/stops/GuideStopEdit';
import { LoadingScreen } from '../common/LoadingScreen/LoadingScreen';
import { ErrorScreen } from '../common/ErrorScreen/ErrorScreen';
import { guideDetailsForCreator } from '../../api/creators/guide_operations';
import { useGuideEditor } from '../../hooks/guide_editing/useGuideEditor';
import type { GuideChanges, GuideDetails, GuideStop, StopDetails, StopSection } from '../../types/guide';
import './GuideManager.css';

interface GuideManagerProps {
  guideId: string;
  isNewGuide?: boolean;
  initialGuide?: GuideDetails; // for new guides
  onSave: (guide: GuideChanges) => Promise<void>;
  onNavigateBack?: () => void;
}

export const GuideManager: React.FC<GuideManagerProps> = ({
  guideId,
  isNewGuide = false,
  initialGuide,
  onSave,
  onNavigateBack,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { overview, stops, details, hasChanges, resetAll, setGuide, collectChanges } = useGuideEditor(
    initialGuide || {
      overview: {
        id: '',
        title: '',
        description: '',
        stops: [],
        url: '',
      },
      stop_details: [],
    }
  );

  // Store setGuide in a ref
  const setGuideRef = useRef(setGuide);
  setGuideRef.current = setGuide;

  useEffect(() => {
    const fetchGuideDetails = async () => {
      if (isNewGuide) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const response = await guideDetailsForCreator({ overview_id: guideId });

        if (!response.isOk) {
          throw new Error('Failed to fetch guide details');
        }
        setGuideRef.current(response);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load guide details');
      } finally {
        setLoading(false);
      }
    };

    fetchGuideDetails();
  }, [guideId, isNewGuide]);

  const validateGuide = () => {
    // Basic validation for new guides
    if (!overview.overview.title?.trim()) {
      return 'Title is required';
    }
    if (!overview.overview.description?.trim()) {
      return 'Description is required';
    }
    if (!overview.overview.headerImage) {
      return 'Header image is required';
    }
    if (stops.stops.length === 0) {
      return 'At least one stop is required';
    }

    // Validate that each stop has details
    for (const stop of stops.stops) {
      const stopDetails = details.getStopDetails(stop.id);
      if (!stopDetails.imageUrl) {
        return `Stop "${stop.title}" requires an image`;
      }
      if (!stopDetails.sections || stopDetails.sections.length === 0) {
        return `Stop "${stop.title}" requires at least one section`;
      }
    }

    return null;
  };

  const handleStartEditing = () => {
    setIsEditing(true);
  };

  const handleCancelEditing = () => {
    if (hasChanges) {
      const shouldCancel = window.confirm('You have unsaved changes. Are you sure you want to cancel?');
      if (!shouldCancel) return;
    }
    resetAll();
    if (isNewGuide) {
      onNavigateBack?.();
    } else {
      setIsEditing(false);
    }
  };

  const handleAddStop = () => {
    // Add the stop
    const newStopId = stops.addStop({
      title: 'New Stop',
      description: '',
    });

    // Initialize empty details for the new stop
    details.initializeDetails(newStopId);
  };

  const handleSave = async () => {
    const validationError = validateGuide();
    if (validationError) {
      alert(validationError);
      return;
    }
    try {
      setIsSaving(true);
      await onSave(collectChanges());
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to save guide:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    if (isEditing && hasChanges) {
      const shouldLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (!shouldLeave) return;
    }
    onNavigateBack?.();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !isEditing) return;
    stops.reorderStops(result.source.index, result.destination.index);
  };

  if (loading) return <LoadingScreen />;
  if (error) return <ErrorScreen message={error} />;

  return (
    <div className='guide-manager'>
      <div className='guide-manager__header'>
        {onNavigateBack && (
          <button className='guide-manager__back-button' onClick={handleBack}>
            <ArrowLeft size={20} />
            Back to Dashboard
          </button>
        )}

        <div className='guide-manager__actions'>
          {isEditing ? (
            <>
              <button className='button button-light' onClick={handleCancelEditing} disabled={isSaving}>
                Cancel
              </button>
              <button className='button button-primary' onClick={handleSave} disabled={isSaving || !hasChanges}>
                {isSaving ? 'Saving...' : 'Save Changes'}
              </button>
            </>
          ) : (
            <button className='button button-primary' onClick={handleStartEditing}>
              Edit Guide
            </button>
          )}
        </div>
      </div>

      <div className='guide-manager__content'>
        <GuideOverviewEdit
          guide={overview.overview}
          isEditing={isEditing}
          onUpdateTitle={title => overview.updateOverview({ title })}
          onUpdateDescription={description => overview.updateOverview({ description })}
          onUpdateMedia={overview.updateMedia}
        />

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='stops'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps} className='guide-manager__stops'>
                {stops.stops.map((stop, index) => (
                  <GuideStopEdit
                    key={stop.id}
                    stop={stop}
                    details={details.getStopDetails(stop.id)}
                    index={index}
                    isEditing={isEditing}
                    onUpdate={(changes: Partial<GuideStop>) => {
                      stops.updateStop(stop.id, changes);
                    }}
                    onUpdateDetails={(changes: Partial<StopDetails>) => {
                      details.updateStopDetails(stop.id, changes);
                    }}
                    onUpdateSection={(sectionId, updates) => {
                      details.updateStopDetails(stop.id, {
                        sectionId,
                        sectionChanges: updates,
                      });
                    }}
                    onDelete={() => stops.deleteStop(stop.id)}
                    onAddSection={(section: StopSection) => details.addSection(stop.id, section)} // Add this line
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {isEditing && (
          <div className='guide-overview__actions'>
            <button className='button button-light' onClick={handleAddStop}>
              <Plus size={20} />
              Add Stop
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
