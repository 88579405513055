import { useState, useCallback } from 'react';
import type { GuideOverview } from '../../types/guide';
import { CaptionedImage, AudioResource, VideoResource } from '../../types/media';

type MediaUpdateType = {
  headerImage: CaptionedImage;
  audio: AudioResource;
  video: VideoResource;
};

export const useGuideOverview = (initialOverview: GuideOverview) => {
  const [state, setState] = useState({
    original: initialOverview,
    pendingChanges: {},
  });

  const setGuide = useCallback((newOverview: GuideOverview) => {
    setState({
      original: newOverview,
      pendingChanges: {},
    });
  }, []);

  const updateOverview = useCallback((changes: Partial<GuideOverview>) => {
    setState(prev => ({
      ...prev,
      pendingChanges: { ...prev.pendingChanges, ...changes },
    }));
  }, []);

  const updateMedia = useCallback(
    <T extends keyof MediaUpdateType>(type: T, media: MediaUpdateType[T] | null) => {
      updateOverview({ [type]: media });
    },
    [updateOverview]
  );

  const resetChanges = useCallback(() => {
    setState(prev => ({ ...prev, pendingChanges: {} }));
  }, []);

  const commitChanges = useCallback(() => {
    setState(prev => ({
      original: { ...prev.original, ...prev.pendingChanges },
      pendingChanges: {},
    }));
    return { ...state.original, ...state.pendingChanges };
  }, [state]);

  return {
    overview: { ...state.original, ...state.pendingChanges },
    setGuide,
    updateOverview,
    updateMedia,
    resetChanges,
    commitChanges,
    hasChanges: Object.keys(state.pendingChanges).length > 0,
    pendingChanges: state.pendingChanges,
  };
};
