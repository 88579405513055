import React, { useState, useEffect } from 'react';
import { ChevronDown, GripVertical, Trash2, Plus } from 'lucide-react';
import { DragDropContext, Draggable, DropResult } from '@hello-pangea/dnd';
import { EditableText } from '../shared/EditableText';
import type { GuideStop, StopDetails, StopSection } from '../../../../types/guide';
import { SectionList } from './StopSectionEditor';
import './GuideStopEdit.css';
import { MediaUploader } from '../../../common/media/generic/MediaUploader';
import { LocationEditor } from '../../../common/location/LocationEditor';

interface GuideStopEditProps {
  stop: GuideStop;
  details?: StopDetails;
  index: number;
  isEditing: boolean;
  onUpdate: (changes: Partial<GuideStop>) => void;
  onUpdateDetails: (changes: Partial<StopDetails>) => void;
  onUpdateSection: (sectionId: string, updates: Partial<StopSection>) => void;
  onDelete: () => void;
  onAddSection: (section: StopSection) => void;
}

export const GuideStopEdit: React.FC<GuideStopEditProps> = ({
  stop,
  details,
  index,
  isEditing,
  onUpdate,
  onUpdateDetails,
  onUpdateSection,
  onDelete,
  onAddSection,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAddingDetails, setIsAddingDetails] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const hasDetails = Boolean(details && details.imageUrl);

  // Validate details whenever they change
  useEffect(() => {
    if (isAddingDetails && details) {
      if (!details.imageUrl) {
        setValidationError('Header image is required');
      } else if (!details.sections.length) {
        setValidationError('At least one section is required');
      } else {
        setValidationError(null);
      }
    }
  }, [isAddingDetails, details]);

  const handleToggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleStartAddingDetails = () => {
    setIsAddingDetails(true);
    // Initialize empty details if none exist
    if (!details) {
      onUpdateDetails({
        title: stop.title,
        description: stop.description,
        tags: [],
        sections: [],
      });
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !isEditing || !details) return;

    const { source, destination } = result;

    // Create a new array of sections
    const reorderedSections = Array.from(details.sections);
    const [removed] = reorderedSections.splice(source.index, 1);
    reorderedSections.splice(destination.index, 0, removed);

    // Update details with the new sections array
    onUpdateDetails({
      sections: reorderedSections,
    });
  };

  const renderSectionsList = () => {
    if (!hasDetails && !isAddingDetails) return null;

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <SectionList
          stopId={stop.id}
          sections={details?.sections || []}
          isEditing={isEditing && (isAddingDetails || hasDetails)}
          onUpdateSection={(sectionId, updates) => {
            console.log('onUpdateSection called');
            console.log(sectionId, updates);
            onUpdateSection(sectionId, updates);
          }}
          onDeleteSection={index => {
            const newSections = [...(details?.sections || [])];
            newSections.splice(index, 1);
            onUpdateDetails({ sections: newSections });
          }}
          addSection={onAddSection}
        />
      </DragDropContext>
    );
  };

  return (
    <Draggable draggableId={stop.id} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`guide-stop ${isEditing ? 'guide-stop--editing' : ''}`}
        >
          <div className='guide-stop__header' onClick={handleToggleExpand}>
            <div {...provided.dragHandleProps} className='guide-stop__drag-handle'>
              <GripVertical size={20} />
            </div>

            <div className='guide-stop__title-container'>
              {isEditing ? (
                <input
                  type='text'
                  value={stop.title}
                  onChange={e => onUpdate({ title: e.target.value })}
                  className='guide-stop__title-input'
                  placeholder='Stop title'
                  onClick={e => e.stopPropagation()}
                />
              ) : (
                <h3 className='guide-stop__title'>{stop.title}</h3>
              )}
            </div>

            <div className='guide-stop__actions'>
              {isEditing && (
                <button
                  type='button'
                  className='guide-stop__delete'
                  onClick={e => {
                    e.stopPropagation();
                    onDelete();
                  }}
                >
                  <Trash2 size={20} />
                </button>
              )}
              <ChevronDown
                size={20}
                className={`guide-stop__chevron ${isExpanded ? 'guide-stop__chevron--expanded' : ''}`}
              />
            </div>
          </div>

          {isExpanded && (
            <div className='guide-stop__content'>
              {isEditing ? (
                <EditableText
                  value={stop.description}
                  onChange={value => onUpdate({ description: value })}
                  multiline
                  label='Description'
                  placeholder='Stop description (required)'
                />
              ) : (
                stop.description && <p className='guide-stop__description'>{stop.description}</p>
              )}

              {isEditing && !hasDetails && !isAddingDetails && (
                <button className='guide-stop__add-details-button' onClick={handleStartAddingDetails}>
                  <Plus size={16} />
                  Add Details
                </button>
              )}

              {(hasDetails || isAddingDetails) && (
                <div className='guide-stop__details'>
                  <div className='guide-stop__details-header'>
                    <h4>Stop Details</h4>
                  </div>

                  {validationError && <div className='guide-stop__validation-message'>{validationError}</div>}

                  <MediaUploader
                    id={`stop-header-${stop.id}`}
                    type='image'
                    value={details?.imageUrl ? { imageUrl: details.imageUrl } : undefined}
                    onChange={media => onUpdateDetails({ imageUrl: media?.imageUrl })}
                    required={true}
                    isEditing={isEditing && (isAddingDetails || hasDetails)}
                  />

                  <LocationEditor
                    id={`stop-location-${stop.id}`}
                    latitude={details?.navigationLatitude}
                    longitude={details?.navigationLongitude}
                    isEditing={isEditing && (isAddingDetails || hasDetails)}
                    onChange={(lat, lng) => onUpdateDetails({ navigationLatitude: lat, navigationLongitude: lng })}
                  />

                  {renderSectionsList()}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};
