import { useState, useCallback } from 'react';
import type { StopDetails, StopSection } from '../../types/guide';

interface StopDetailChanges {
  sectionOrder?: string[];
  sectionChanges?: Record<string, Partial<StopSection>>;
  imageUrl?: string;
  navigationLatitude?: number;
  navigationLongitude?: number;
  [key: string]: any; // For other properties
}

interface DetailsState {
  details: Record<string, StopDetails>;
  pendingChanges: Record<string, StopDetailChanges>;
  originalDetails?: Record<string, StopDetails>;
}

export const useStopDetails = (initialDetails: Record<string, StopDetails>) => {
  const [state, setState] = useState<DetailsState>({
    details: initialDetails,
    pendingChanges: {},
    originalDetails: initialDetails,
  });

  const setDetails = useCallback((newDetails: Record<string, StopDetails>) => {
    setState(prev => ({
      details: newDetails,
      pendingChanges: {},
      originalDetails: newDetails,
    }));
  }, []);

  const resetChanges = useCallback(() => {
    setState(prev => ({
      details: prev.originalDetails || prev.details,
      pendingChanges: {},
    }));
  }, []);

  const getStopDetails = useCallback(
    (stopId: string): StopDetails => {
      const currentDetails = state.details[stopId] || {
        title: '',
        description: '',
        tags: [],
        sections: [],
      };
      const pendingChanges = state.pendingChanges[stopId] || {};

      // Apply section content changes if they exist
      const sections = [...currentDetails.sections];

      // If we have a new section order, reorder the sections
      if (pendingChanges.sectionOrder) {
        const orderedSections = [];
        for (const sectionId of pendingChanges.sectionOrder) {
          const section = sections.find(s => s.id === sectionId);
          if (section) {
            orderedSections.push(section);
          }
        }
        sections.length = 0;
        sections.push(...orderedSections);
      }

      // Apply any content changes to sections
      if (pendingChanges.sectionChanges) {
        sections.forEach((section, index) => {
          if (pendingChanges.sectionChanges?.[section.id]) {
            sections[index] = {
              ...section,
              ...pendingChanges.sectionChanges[section.id],
            };
          }
        });
      }

      const { sectionOrder, sectionChanges, ...otherChanges } = pendingChanges;

      return {
        ...currentDetails,
        ...otherChanges,
        sections,
      };
    },
    [state]
  );

  const updateStopDetails = useCallback(
    (
      stopId: string,
      changes: Partial<StopDetails> & {
        sectionId?: string;
        sectionChanges?: Partial<StopSection>;
      }
    ) => {
      console.log('updateStopDetails called with:', { stopId, changes }); // Add this
      // console.log('Current state:', setState.current);
      setState(prev => {
        const currentChanges = prev.pendingChanges[stopId] || {};

        // Handle section reordering
        if (changes.sections) {
          return {
            ...prev,
            pendingChanges: {
              ...prev.pendingChanges,
              [stopId]: {
                ...currentChanges,
                // Only store the order of sections, not the sections themselves
                sectionOrder: changes.sections.map(section => section.id),
              },
            },
          };
        }

        // Handle section content changes
        if (changes.sectionId && changes.sectionChanges) {
          const sectionChanges = {
            ...(currentChanges.sectionChanges || {}),
            [changes.sectionId]: {
              ...(currentChanges.sectionChanges?.[changes.sectionId] || {}),
              ...changes.sectionChanges,
            },
          };

          return {
            ...prev,
            pendingChanges: {
              ...prev.pendingChanges,
              [stopId]: {
                ...currentChanges,
                sectionChanges,
              },
            },
          };
        }

        // Handle other changes, including imageUrl and navigation coordinates
        const { sectionId, sectionChanges, sections, ...otherChanges } = changes;

        // Create updated changes object with current changes as base
        const updatedChanges: StopDetailChanges = { ...currentChanges };

        // Only add properties that exist in otherChanges with non-undefined values
        Object.entries(otherChanges).forEach(([key, value]) => {
          if (value !== undefined) {
            updatedChanges[key] = value;
          }
        });

        console.log('Final updatedChanges:', updatedChanges);

        return {
          ...prev,
          pendingChanges: {
            ...prev.pendingChanges,
            [stopId]: updatedChanges,
          },
        };
      });
    },
    []
  );

  const initializeDetails = useCallback((stopId: string) => {
    setState(prev => ({
      ...prev,
      details: {
        ...prev.details,
        [stopId]: {
          title: '',
          description: '',
          tags: [],
          sections: [],
        },
      },
    }));
  }, []);

  const commitChanges = useCallback(() => {
    setState(prev => {
      const updatedDetails = { ...prev.details };

      // Apply all pending changes to the details
      Object.entries(prev.pendingChanges).forEach(([stopId, changes]) => {
        const currentDetails = updatedDetails[stopId];
        if (!currentDetails) return;

        // Handle reordered sections
        if (changes.sectionOrder) {
          // Reorder sections based on the sectionOrder array
          const orderedSections = [];
          for (const sectionId of changes.sectionOrder) {
            const section = currentDetails.sections.find(s => s.id === sectionId);
            if (section) {
              orderedSections.push(section);
            }
          }
          currentDetails.sections = orderedSections;
        }

        // Handle section content changes
        if (changes.sectionChanges) {
          currentDetails.sections = currentDetails.sections.map(section => ({
            ...section,
            ...(changes.sectionChanges?.[section.id] || {}),
          }));
        }

        // Handle other changes
        const { sectionOrder, sectionChanges, ...otherChanges } = changes;
        Object.assign(currentDetails, otherChanges);
      });

      return {
        details: updatedDetails,
        pendingChanges: {},
      };
    });

    return state.details;
  }, [state.details]);

  const addSection = useCallback((stopId: string, newSection: StopSection) => {
    setState(prev => {
      const currentDetails = prev.details[stopId] || {
        title: '',
        description: '',
        tags: [],
        sections: [],
      };

      return {
        ...prev,
        details: {
          ...prev.details,
          [stopId]: {
            ...currentDetails,
            sections: [...currentDetails.sections, newSection],
          },
        },
      };
    });
  }, []);

  // Track section content changes
  const updateSection = useCallback((stopId: string, sectionId: string, changes: Partial<StopSection>) => {
    setState(prev => ({
      ...prev,
      pendingChanges: {
        ...prev.pendingChanges,
        [stopId]: {
          ...prev.pendingChanges[stopId],
          sectionChanges: {
            ...(prev.pendingChanges[stopId]?.sectionChanges || {}),
            [sectionId]: {
              ...(prev.pendingChanges[stopId]?.sectionChanges?.[sectionId] || {}),
              ...changes,
            },
          },
        },
      },
    }));
  }, []);

  return {
    getStopDetails,
    setDetails,
    updateStopDetails,
    initializeDetails,
    resetChanges,
    commitChanges,
    addSection,
    hasChanges: Object.keys(state.pendingChanges).length > 0,
    state,
    updateSection,
    pendingChanges: state.pendingChanges,
  };
};
