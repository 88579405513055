import React from 'react';
import { Upload, X, Loader, Video } from 'lucide-react';
import { useMediaUpload } from '../../../../hooks/useMediaUpload';
import { VideoResource } from '../../../../types/media';
import './VideoPlayer.css';
import '../SharedStyles.css';

interface VideoPlayerProps {
  id: string;
  videoUrl?: string;
  thumbnailUrl?: string;
  title?: string;
  className?: string;
  isEditing?: boolean;
  onChange?: (media: VideoResource | null) => void;
  required?: boolean;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  id,
  videoUrl,
  thumbnailUrl,
  isEditing = false,
  onChange,
  required = false,
}) => {
  const {
    isUploading,
    isDragging,
    error,
    fileInputRef,
    setIsDragging,
    openFileSelector,
    handleDrop,
    handleChange,
    acceptedFormats,
  } = useMediaUpload<'video'>({
    type: 'video',
    onUpload: onChange!,
  });

  if (!videoUrl && isEditing) {
    return (
      <div className='video-player' id={`video-player-${id}`}>
        <input
          ref={fileInputRef}
          type='file'
          accept={acceptedFormats}
          onChange={handleChange}
          className='media-dropzone__input-hidden'
          id={`video-input-${id}`}
          aria-label={'Upload video'}
        />

        <div
          className={`media-dropzone ${isDragging ? 'media-dropzone--active' : ''}`}
          onDragOver={e => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop}
          onClick={openFileSelector}
          role='button'
          tabIndex={0}
          aria-labelledby={`video-player-title-${id}`}
        >
          {isUploading ? (
            <div className='media-dropzone__uploading'>
              <Loader className='media-dropzone__spinner' />
              <span>Uploading...</span>
            </div>
          ) : (
            <>
              <div className='media-dropzone__icon'>
                <Video size={48} />
              </div>
              <div className='media-dropzone__text'>
                <span>Drag and drop or click to select a video file</span>
              </div>
            </>
          )}
        </div>
        {error && <div className='media-error'>{error}</div>}
      </div>
    );
  }

  return (
    <div className='video-player' id={`video-player-${id}`}>
      <div className='video-player__content'>
        <video
          controls
          src={videoUrl}
          poster={thumbnailUrl}
          className='video-player__video'
          id={`video-controls-${id}`}
        />

        {isEditing && (
          <>
            <input
              ref={fileInputRef}
              type='file'
              accept={acceptedFormats}
              onChange={handleChange}
              className='media-dropzone__input-hidden'
              aria-label='Replace video'
            />
            <button
              onClick={openFileSelector}
              className='media-replace-button'
              disabled={isUploading}
              aria-label='Replace video'
            >
              {isUploading ? (
                <Loader className='media-dropzone__spinner' />
              ) : (
                <>
                  <Upload size={16} />
                  <span>Replace video</span>
                </>
              )}
            </button>

            {!required && (
              <button onClick={() => onChange?.(null)} className='media-remove-button' aria-label='Remove video'>
                <X size={20} />
              </button>
            )}
          </>
        )}
      </div>
      {error && <div className='media-error'>{error}</div>}
    </div>
  );
};
