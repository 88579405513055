import React from 'react';
import './form/SharedFormStyles.css';

interface EditableTextProps {
  value: string;
  onChange: (value: string) => void;
  multiline?: boolean;
  label?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
}

export const EditableText: React.FC<EditableTextProps> = ({
  value,
  onChange,
  multiline = false,
  label,
  placeholder,
  error,
  disabled = false,
}) => {
  const Component = multiline ? 'textarea' : 'input';

  return (
    <div className='form-field-wrapper'>
      {label && <label className='form-field-label'>{label}</label>}

      <Component
        className={`form-field ${multiline ? 'form-field--multiline' : ''} ${error ? 'form-field--error' : ''}`}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        disabled={disabled}
        rows={multiline ? 4 : undefined}
      />

      {error && <div className='form-field-error'>{error}</div>}
    </div>
  );
};
