import React from 'react';

import type { GuideOverview } from '../../../../types/guide';
import type { CaptionedImage, AudioResource, VideoResource } from '../../../../types/media';
import './GuideOverviewEdit.css';
import { AudioPlayer } from '../../../common/media/AudioPlayer/AudioPlayer';
import { VideoPlayer } from '../../../common/media/VideoPlayer/VideoPlayer';
import { ImageViewer } from '../../../common/media/ImageViewer/ImageViewer';
import { MediaUploader } from '../../../common/media/generic/MediaUploader';

interface GuideOverviewEditProps {
  guide: GuideOverview;
  isEditing: boolean;
  onUpdateTitle: (title: string) => void;
  onUpdateDescription: (description: string) => void;
  onUpdateMedia: (
    type: 'headerImage' | 'audio' | 'video',
    media: CaptionedImage | AudioResource | VideoResource | null
  ) => void;
}

export const GuideOverviewEdit: React.FC<GuideOverviewEditProps> = ({
  guide,
  isEditing,
  onUpdateTitle,
  onUpdateDescription,
  onUpdateMedia,
}) => {
  const renderContent = () => {
    if (isEditing) {
      return (
        <>
          <div className='guide-overview__editable-field'>
            <input
              type='text'
              value={guide.title}
              onChange={e => onUpdateTitle(e.target.value)}
              className='guide-overview__editable-input'
              placeholder='Enter guide title'
            />
          </div>

          <div className='guide-overview__editable-field'>
            <textarea
              value={guide.description}
              onChange={e => onUpdateDescription(e.target.value)}
              className='guide-overview__editable-textarea'
              placeholder='Enter guide description'
              rows={4}
            />
          </div>

          <>
            <MediaUploader
              id={`overview-image-${guide.id}`}
              type='image'
              value={guide.headerImage || undefined}
              onChange={media => onUpdateMedia('headerImage', media)}
              required
            />

            <MediaUploader
              id={`overview-audio-${guide.id}`}
              type='audio'
              value={guide.audio || undefined}
              onChange={media => onUpdateMedia('audio', media)}
            />

            <MediaUploader
              id={`overview-video-${guide.id}`}
              type='video'
              value={guide.video || undefined}
              onChange={media => onUpdateMedia('video', media)}
            />
          </>
        </>
      );
    }

    return (
      <>
        <h2 className='guide-overview__title'>{guide.title}</h2>
        <p className='guide-overview__description'>{guide.description}</p>

        {guide.headerImage && (
          <ImageViewer
            id={`overview-image-${guide.id}`}
            imageUrl={guide.headerImage.imageUrl}
            caption={guide.headerImage.caption}
            isEditing={false}
          />
        )}

        {guide.audio && (
          <AudioPlayer
            id={`overview-audio-${guide.id}`}
            title={guide.audio.title}
            audioUrl={guide.audio.audioFileUrl}
            isEditing={false}
          />
        )}

        {guide.video && (
          <VideoPlayer
            id={`overview-video-${guide.id}`}
            videoUrl={guide.video.videoFileUrl}
            // Don't read thumbnailUrl for now
            thumbnailUrl={undefined}
            isEditing={false}
          />
        )}
      </>
    );
  };

  return <div className={`guide-overview ${isEditing ? 'guide-overview--editing' : ''}`}>{renderContent()}</div>;
};
