import React from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Trash2, GripVertical } from 'lucide-react';
import { EditableText } from '../shared/EditableText';
import { MediaUploader } from '../../../common/media/generic/MediaUploader';
import type { StopSection } from '../../../../types/guide';
import './StopSectionEditor.css';
import { SectionCreationControls } from './SectionCreationControls';

interface SectionListProps {
  stopId: string;
  sections: StopSection[];
  isEditing: boolean;
  onUpdateSection: (sectionId: string, updates: Partial<StopSection>) => void;
  onDeleteSection: (index: number) => void;
  addSection: (section: StopSection) => void; // Updated prop type
}

export const SectionList: React.FC<SectionListProps> = ({
  stopId,
  sections,
  isEditing,
  onUpdateSection,
  onDeleteSection,
  addSection,
}) => {
  const handleAddSection = (type: 'title' | 'paragraph' | 'image' | 'audio' | 'video') => {
    const newSection: StopSection = {
      id: `temp-${Date.now()}-${type}-${Math.random().toString(36).slice(2, 7)}`,
      type,
      title: type === 'title' ? '' : undefined,
      paragraph: type === 'paragraph' ? '' : undefined,
      captionedImage: type === 'image' ? undefined : undefined,
      audio: type === 'audio' ? undefined : undefined,
      video: type === 'video' ? undefined : undefined,
    };

    addSection(newSection);
  };

  return (
    <div className='guide-stop__sections'>
      <Droppable droppableId={`stop-${stopId}-sections`} type={`sections-${stopId}`}>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps} className='section-list'>
            {sections.map((section, index) => (
              <StopSectionEditor
                key={section.id}
                section={section}
                index={index}
                isEditing={isEditing}
                onUpdate={updates => onUpdateSection(section.id, updates)}
                onDelete={() => onDeleteSection(index)}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {isEditing && <SectionCreationControls onAddSection={handleAddSection} />}
    </div>
  );
};

interface StopSectionEditorProps {
  section: StopSection;
  index: number;
  isEditing: boolean;
  onUpdate: (updates: Partial<StopSection>) => void;
  onDelete: () => void;
}

export const StopSectionEditor: React.FC<StopSectionEditorProps> = ({
  section,
  index,
  isEditing,
  onUpdate,
  onDelete,
}) => {
  const renderContent = () => {
    // Base the rendering on section.type rather than checking for existing content
    switch (section.type) {
      case 'title':
        return isEditing ? (
          <EditableText
            value={section.title || ''}
            onChange={value => onUpdate({ title: value })}
            placeholder='Section heading'
          />
        ) : (
          section.title && <h2 className='stop-section__title'>{section.title}</h2>
        );

      case 'paragraph':
        return isEditing ? (
          <EditableText
            value={section.paragraph || ''}
            onChange={value => onUpdate({ paragraph: value })}
            multiline
            placeholder='Section text'
          />
        ) : (
          section.paragraph && <p className='stop-section__paragraph'>{section.paragraph}</p>
        );

      case 'image':
        return (
          <div className='stop-section__image'>
            <MediaUploader
              id={`section-image-${section.id}`}
              type='image'
              value={section.captionedImage}
              onChange={media => onUpdate({ captionedImage: media || undefined })}
              isEditing={isEditing}
            />
          </div>
        );

      case 'audio':
        return (
          <div className='stop-section__audio'>
            <MediaUploader
              id={`section-audio-${section.id}`}
              type='audio'
              value={section.audio}
              onChange={media => onUpdate({ audio: media || undefined })}
              isEditing={isEditing}
            />
          </div>
        );

      case 'video':
        return (
          <div className='stop-section__video'>
            <MediaUploader
              id={`section-video-${section.id}`}
              type='video'
              value={section.video}
              onChange={media => onUpdate({ video: media || undefined })}
              isEditing={isEditing}
            />
          </div>
        );

      default:
        return null;
    }
  };

  if (isEditing) {
    return (
      <Draggable draggableId={section.id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`stop-section-edit ${snapshot.isDragging ? 'dragging' : ''}`}
          >
            <div {...provided.dragHandleProps} className='stop-section-edit__drag'>
              <GripVertical size={20} />
            </div>

            <div className='stop-section-edit__content'>{renderContent()}</div>

            <button onClick={onDelete} className='stop-section-edit__delete'>
              <Trash2 size={20} />
            </button>
          </div>
        )}
      </Draggable>
    );
  }

  // View mode
  return (
    <div className='stop-section'>
      <div className='stop-section__content'>{renderContent()}</div>
    </div>
  );
};
