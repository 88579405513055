import { useCallback, useEffect, useRef } from 'react';
import { useGuideOverview } from './useGuideOverview';
import { useGuideStops } from './useGuideStops';
import { useStopDetails } from './useStopDetails';
import type { GuideChanges, GuideDetails, StopChange } from '../../types/guide';
import { updateGuideForCreator } from '../../api/creators/guide_operations';

export const useGuideEditor = (initialGuide: GuideDetails) => {
  const guideId = useRef(initialGuide.overview.id);
  const overview = useGuideOverview(initialGuide.overview);
  const stops = useGuideStops(initialGuide.overview.stops);
  const details = useStopDetails(
    Object.fromEntries(initialGuide.overview.stops.map((stop, index) => [stop.id, initialGuide.stop_details[index]]))
  );

  const collectChanges = useCallback((): GuideChanges => {
    const changes: GuideChanges = {
      id: guideId.current,
    };

    // Add overview changes if any exist
    if (overview.hasChanges) {
      changes.overview = {
        id: overview.overview.id,
        changes: overview.pendingChanges,
      };
    }

    // Add stop order if it has changed
    if (stops.orderChanged) {
      changes.stopOrder = stops.currentOrder;
    }

    // Collect stop and section changes
    const stopChanges: StopChange[] = [];

    // First, process stops with content changes
    if (stops.hasPendingContentChanges) {
      Object.entries(stops.pendingChanges).forEach(([stopId, contentChanges]) => {
        if (Object.keys(contentChanges).length > 0) {
          stopChanges.push({
            stopId,
            changes: contentChanges,
          });
        }
      });
    }

    // Process each stop that has pending changes
    Object.entries(details.pendingChanges).forEach(([stopId, stopChange]) => {
      const existingStopChange = stopChanges.find(change => change.stopId === stopId);

      if (existingStopChange) {
        // When merging with existing stop change, only include defined values
        const detailsChanges = Object.entries(stopChange)
          .filter(([key, value]) => !['sectionOrder', 'sectionChanges'].includes(key) && value !== undefined)
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value,
            }),
            {}
          );

        existingStopChange.changes = {
          ...existingStopChange.changes,
          ...detailsChanges,
        };

        if (stopChange.sectionOrder) {
          existingStopChange.sectionOrder = stopChange.sectionOrder;
        }
        if (stopChange.sectionChanges) {
          existingStopChange.sectionChanges = Object.entries(stopChange.sectionChanges).map(([sectionId, changes]) => ({
            sectionId,
            changes,
          }));
        }
      } else {
        // For new stop changes, only include defined values
        const changes = Object.entries(stopChange)
          .filter(([key, value]) => !['sectionOrder', 'sectionChanges'].includes(key) && value !== undefined)
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value,
            }),
            {}
          );

        const newStopChange: StopChange = {
          stopId,
          changes,
        };

        if (stopChange.sectionOrder) {
          newStopChange.sectionOrder = stopChange.sectionOrder;
        }
        if (stopChange.sectionChanges) {
          newStopChange.sectionChanges = Object.entries(stopChange.sectionChanges).map(([sectionId, changes]) => ({
            sectionId,
            changes,
          }));
        }

        if (Object.keys(changes).length > 0 || newStopChange.sectionOrder || newStopChange.sectionChanges?.length) {
          stopChanges.push(newStopChange);
        }
      }
    });

    if (stopChanges.length > 0) {
      changes.stopChanges = stopChanges;
    }

    return changes;
  }, [
    overview.hasChanges,
    overview.overview.id,
    overview.pendingChanges,
    stops.orderChanged,
    stops.currentOrder,
    stops.hasPendingContentChanges,
    stops.pendingChanges,
    details.pendingChanges,
  ]);

  const hasChanges = overview.hasChanges || stops.hasChanges || details.hasChanges;

  useEffect(() => {
    if (overview.hasChanges || stops.hasChanges || stops.hasPendingContentChanges || details.hasChanges) {
      console.group('🔄 Guide Editor State Update');

      const changes = collectChanges();
      console.log('Complete GuideChanges:', changes);

      if (changes.overview) {
        console.group('📝 Overview Changes');
        console.log('ID:', changes.overview.id);
        console.log('Changes:', changes.overview.changes);
        console.groupEnd();
      }

      if (changes.stopOrder) {
        console.group('🔀 Stop Order Changes');
        console.log('New Order:', changes.stopOrder);
        console.groupEnd();
      }

      if (changes.stopChanges?.length) {
        console.group('🛑 Stop Changes');
        changes.stopChanges.forEach(stopChange => {
          console.group(`Stop: ${stopChange.stopId}`);

          if (Object.keys(stopChange.changes).length) {
            console.log('Content Changes:', stopChange.changes);
          }

          // Log section reordering
          if (stopChange.changes.sections) {
            console.log(
              'Section Order Changed:',
              stopChange.changes.sections.map(s => s.id)
            );
          }

          if (stopChange.sectionChanges?.length) {
            console.group('Section Content Changes');
            stopChange.sectionChanges.forEach(sectionChange => {
              console.log(`Section ${sectionChange.sectionId}:`, sectionChange.changes);
            });
            console.groupEnd();
          }

          console.groupEnd();
        });
        console.groupEnd();
      }

      console.group('📊 Change Statistics');
      console.log('Overview Changed:', !!changes.overview);
      console.log('Stop Order Changed:', !!changes.stopOrder);
      console.log('Stops Modified:', changes.stopChanges?.length ?? 0);
      console.log(
        'Total Sections Modified:',
        changes.stopChanges?.reduce(
          (acc, stop) => acc + (stop.sectionChanges?.length ?? 0) + (stop.changes.sections ? 1 : 0),
          0
        ) ?? 0
      );
      console.groupEnd();

      console.groupEnd();
    }
  }, [
    overview.hasChanges,
    overview.pendingChanges,
    stops.hasChanges,
    stops.hasPendingContentChanges,
    stops.pendingChanges,
    details.hasChanges,
    details.pendingChanges,
    collectChanges,
  ]);

  const resetAll = useCallback(() => {
    overview.resetChanges();
    stops.resetChanges();
    details.resetChanges();
  }, [overview, stops, details]);

  const saveChanges = useCallback(async () => {
    const changes = collectChanges();

    try {
      await updateGuideForCreator({
        changes,
      });

      // After successful save, commit all changes locally
      overview.commitChanges();
      stops.commitChanges();
      details.commitChanges();
    } catch (error) {
      console.error('Failed to save guide changes:', error);
      throw error;
    }
  }, [overview, stops, details, collectChanges]);

  const setGuide = useCallback(
    (guide: GuideDetails) => {
      guideId.current = guide.overview.id;
      overview.setGuide(guide.overview);
      stops.setStops(guide.overview.stops);
      details.setDetails(
        Object.fromEntries(guide.overview.stops.map((stop, index) => [stop.id, guide.stop_details[index]]))
      );
    },
    [overview, stops, details]
  );

  return {
    overview,
    stops,
    details,
    hasChanges,
    resetAll,
    saveChanges,
    setGuide,
    collectChanges,
  };
};
