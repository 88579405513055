import { useState, useCallback } from 'react';
import type { GuideStop } from '../../types/guide';

interface StopsState {
  currentOrder: string[];
  originalOrder: string[];
  stops: Record<string, GuideStop>;
  pendingChanges: Record<string, Partial<GuideStop>>;
}

export const useGuideStops = (initialStops: GuideStop[]) => {
  const [state, setState] = useState<StopsState>({
    currentOrder: initialStops.map(stop => stop.id),
    originalOrder: initialStops.map(stop => stop.id),
    stops: Object.fromEntries(initialStops.map(stop => [stop.id, stop])),
    pendingChanges: {},
  });

  const setStops = useCallback((newStops: GuideStop[]) => {
    setState({
      currentOrder: newStops.map(stop => stop.id),
      originalOrder: newStops.map(stop => stop.id),
      stops: Object.fromEntries(newStops.map(stop => [stop.id, stop])),
      pendingChanges: {},
    });
  }, []);

  const updateStop = useCallback((stopId: string, changes: Partial<GuideStop>) => {
    setState(prev => ({
      ...prev,
      pendingChanges: {
        ...prev.pendingChanges,
        [stopId]: {
          ...prev.pendingChanges[stopId],
          ...changes,
        },
      },
    }));
  }, []);

  const reorderStops = useCallback((sourceIndex: number, destinationIndex: number) => {
    setState(prev => {
      const newOrder = [...prev.currentOrder];
      const [moved] = newOrder.splice(sourceIndex, 1);
      newOrder.splice(destinationIndex, 0, moved);
      return {
        ...prev,
        currentOrder: newOrder,
      };
    });
  }, []);

  const addStop = useCallback((stopData: Omit<GuideStop, 'id'>) => {
    const newStopId = `new-${Date.now()}-${Math.random().toString(36).slice(2, 7)}`;
    const newStop: GuideStop = {
      id: newStopId,
      ...stopData,
    };

    setState(prev => ({
      ...prev,
      currentOrder: [...prev.currentOrder, newStopId],
      stops: {
        ...prev.stops,
        [newStopId]: newStop,
      },
    }));

    return newStopId; // Return the new ID
  }, []);

  const deleteStop = useCallback((stopId: string) => {
    setState(prev => {
      const { [stopId]: removedStop, ...remainingStops } = prev.stops;
      const { [stopId]: removedChanges, ...remainingChanges } = prev.pendingChanges;

      return {
        ...prev,
        currentOrder: prev.currentOrder.filter(id => id !== stopId),
        stops: remainingStops,
        pendingChanges: remainingChanges, // This now properly maintains the type
      };
    });
  }, []);

  const resetChanges = useCallback(() => {
    setState(prev => ({
      ...prev,
      currentOrder: prev.originalOrder,
      pendingChanges: {},
    }));
  }, []);

  const commitChanges = useCallback(() => {
    setState(prev => {
      // Apply pending changes to stops
      const updatedStops = { ...prev.stops };
      Object.entries(prev.pendingChanges).forEach(([stopId, changes]) => {
        if (updatedStops[stopId]) {
          updatedStops[stopId] = {
            ...updatedStops[stopId],
            ...changes,
          };
        }
      });

      return {
        currentOrder: prev.currentOrder,
        originalOrder: prev.currentOrder, // Update original order to match current
        stops: updatedStops,
        pendingChanges: {},
      };
    });
  }, []);

  // Computed properties
  const hasPendingContentChanges = Object.keys(state.pendingChanges).length > 0;
  const orderChanged = state.originalOrder.join(',') !== state.currentOrder.join(',');
  const hasChanges = orderChanged || hasPendingContentChanges;

  // Get stops in current order with pending changes applied
  const stops = state.currentOrder.map(stopId => ({
    ...state.stops[stopId],
    ...state.pendingChanges[stopId],
  }));

  return {
    stops,
    setStops,
    updateStop,
    reorderStops,
    addStop,
    deleteStop,
    resetChanges,
    commitChanges,
    hasChanges,
    hasPendingContentChanges,
    pendingChanges: state.pendingChanges,
    currentOrder: state.currentOrder,
    orderChanged,
    originalOrder: state.originalOrder,
  };
};
