import { asyncPostRequest, asyncPutRequest } from './requests';

export const s3SignedUrl = async (filename, filetype) => {
  return await asyncPostRequest(
    '/api/s3/sign_s3',
    {
      filename: filename,
      filetype: filetype,
    },
    true
  );
};

export const putFile = async (url, fileType, blob) => {
  return await asyncPutRequest(url, blob, { contentType: fileType, isProtected: false, isFormData: true });
};
